import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import withAuth from "../../../../../../../../Hoc/withAuth";
import { Stack } from '@mui/material';

const BarManager = ({ validRows }) => {

  console.log('validRows:', validRows);


  const Evaluations = Array.isArray(validRows)
  ? validRows?.flatMap(campaign => campaign.campaign || [])
  : [];  // Default to an empty array if validRows is not an array



  const NotificationGroup = Array.isArray(validRows)
  ? validRows?.flatMap(campaign => {
        return campaign.notifications_group || [];
      })
      .filter(group => group.user_type !== "Manager" && group.user_type !== "Team Leader")
      .filter((group, index, self) =>
        self.findIndex(g => g.user_id === group.user_id) === index
      )
  : [];



  const aData = Array.isArray(NotificationGroup)
  ? NotificationGroup.map(group => {
      const failedEvaluations = Evaluations?.filter(
        evaluation =>
          evaluation.evaluation_qa_users?.id === group.user_id &&
          evaluation.status === 'Failed'
      );
      return failedEvaluations?.length;
    })
  : [];

  

const bData = Array.isArray(NotificationGroup)
  ? NotificationGroup.map(group => {
      const completedEvaluations = Evaluations?.filter(
        evaluation =>
          evaluation.evaluation_qa_users?.id === group.user_id &&
          evaluation.status === 'Completed'
      );
      return completedEvaluations?.length;
    })
  : [];

  const cData = Array.isArray(NotificationGroup)
  ? NotificationGroup.map(group => {
      const newEvaluations = Evaluations?.filter(
        evaluation =>
          evaluation.evaluation_qa_users?.id === group.user_id &&
          evaluation.status === 'New'
      );
      return newEvaluations?.length;
    })
  : [];

  const dData = Array.isArray(NotificationGroup)
  ? NotificationGroup.map(group => {
      const busyEvaluations = Evaluations?.filter(
        evaluation =>
          evaluation.evaluation_qa_users?.id === group.user_id &&
          evaluation.status === 'Busy'
      );
      return busyEvaluations?.length;
    })
  : [];



  const xLabels = Array.isArray(NotificationGroup)
    ? NotificationGroup.map(group => {
        const userEvaluation = Evaluations?.find(
          evaluation => evaluation.evaluation_qa_users?.id === group.user_id
        );
        return userEvaluation?.evaluation_qa_users?.name || 'Unknown';
      })
    : [];



  return (
    <Stack  sx={{ml: 15, mr: 10}}>
    <BarChart
    height={450}
    width={550}

      series={[
        { data: bData || [], label: 'Completed', id: 'completedId', stack: 'total' }, 
             
        { data: cData || [], label: 'New', id: 'newId', stack: 'total' },
        { data: aData || [], label: 'Failed', id: 'failedId', stack: 'total' },
        { data: dData || [], label: 'Busy', id: 'busyId', stack: 'total' },
        
      ]}
      yAxis={[{  label: 'Number of Evaluations Per User' }]}
      xAxis={[{ data: xLabels, scaleType: 'band', label: 'QA Agent' }]}
    />
    </Stack>
  );
};

export default withAuth(BarManager);
